import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import WhiteLogo from "../../assets/images/WhiteLogo.png";
import "./MobileNav.scss";
import { ButtonToolbar } from "react-bootstrap";
const MobileNav = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const ServicesScreen = () => {
    navigate(`/service`);
  };
  const PortfolioScreen = () => {
    navigate(`/portfolio`);
  };
  const gotoHomePage = () => {
    navigate("/");
  };
  const TestimonialScreen = () => {
    navigate(`/testimonial`);
  };
  const gotoAboutUs = () => {
    navigate(`/about-us`);
  };
  const gotoContactUs = () => {
    navigate(`/contact-us`);
  };
  return (
    <div className="mobile-nav-main">
      <Offcanvas show={show} onHide={handleClose} placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="mob-logo" onClick={gotoHomePage}>
              <img src={WhiteLogo} alt="" />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="mobile-menu-main">
            <li
              onClick={() => {
                ServicesScreen();
                handleClose();
              }}
            >
              Services
            </li>
            <li
              onClick={() => {
                PortfolioScreen();
                handleClose();
              }}
            >
              Portfolio
            </li>
            <li
              onClick={() => {
                TestimonialScreen();
                handleClose();
              }}
            >
              Testimonial
            </li>
            <li
              onClick={() => {
                gotoAboutUs();
                handleClose();
              }}
            >
              About Us
            </li>
            <li
              onClick={() => {
                gotoContactUs();
                handleClose();
              }}
            >
              Contact Us
            </li>
            {/* <li>Career</li> */}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        fill="black"
        className="bi bi-list"
        viewBox="0 0 16 16"
        onClick={handleShow}
      >
        <path
          fillRule="evenodd"
          d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
        />
      </svg>
    </div>
  );
};

export default MobileNav;
