import React from 'react'
import WhiteLogo from "../../assets/images/WhiteLogo.png"
import Linkdine from "../../assets/images/Linkdine.svg"
import Insta from "../../assets/images/Insta.svg"
import FaceBook from "../../assets/images/Facebook.svg"
import Twitter from "../../assets/images/Twitter.svg"
import "./Footer.scss"
const Footer = () => {
    return (
        <footer>
            <div className="f-logo">
                <img src={WhiteLogo} alt="" />
            </div>
            <div className="follow-us">
                Follow Us
            </div>
            <div className="social-media">
                <div className="linkdine">
                    <a href="https://www.linkedin.com/company/erothin/" target='_blank'><img src={Linkdine} alt="" /></a>

                </div>
                <div className="insta">
                    <a href="https://www.instagram.com/erothintech/" target='_blank'><img src={Insta} alt="" /></a>
                </div>
                <div className="face-book">
                    <a href="https://www.facebook.com/erothintech" target='_blank'><img src={FaceBook} alt="" /></a>
                </div>
                <div className="twiiter">
                    <a href="https://twitter.com/erothintech" target='_blank'>
                        <img src={Twitter} alt="" />
                    </a>
                </div>
            </div>
            <div className="copy-right">
                © Copyright. All rights reserved
            </div>
        </footer>
    )
}

export default Footer
