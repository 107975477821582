import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initApp } from "./redux/actions/AppAction";
import Loading from "./components/Loading/Loading";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop";
const HomeScreen = React.lazy(() => import("./screens/Home/HomeScreen"));
const ServicesScreen = React.lazy(() =>
  import("./screens/Services/ServicesScreen")
);
const PortfolioScreen = React.lazy(() =>
  import("./screens/Portfolio/PortfolioScreen")
);
const TestimonialScreen = React.lazy(() =>
  import("./screens/Testimonial/TestimonialScreen")
);
const AboutUsScreen = React.lazy(() =>
  import("./screens/AboutUs/AboutUsScreen")
);
const ContactUsScreen = React.lazy(() =>
  import("./screens/ContactUs/ContactUsScreen")
);
const NotFoundPage = React.lazy(() => import("./screens/NotFoundPage"));

const AppLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.app);
  useEffect(() => {
    // if (location.pathname !== "/login" && !isLoggedIn) {
    //   navigate("/login");
    // }
  }, [location, isLoggedIn, navigate]);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (!init) {
      setInit(true);
      dispatch(initApp());
    }
  }, [dispatch, init]);
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route exact path="/" element={<HomeScreen />} />
            <Route exact path="/service" element={<ServicesScreen />} />
            <Route exact path="/portfolio" element={<PortfolioScreen />} />
            <Route exact path="/testimonial" element={<TestimonialScreen />} />
            <Route exact path="/about-us" element={<AboutUsScreen />} />
            <Route exact path="/contact-us" element={<ContactUsScreen />} />
          </Route>
          <Route exact path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
};

export default App;
