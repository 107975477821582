 import React, { useEffect } from "react";
import Logo from "../../assets/images/Logo.svg";
import { useNavigate } from "react-router-dom";
import "./Header.scss";
import MobileNav from "./MobileNav";

const Header = () => {
  window.onscroll = function () {
    myFunction();
  };
  function myFunction() {
    var winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    var height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    var scrolled = (winScroll / height) * 100;
    document.getElementById("progress-bar").style.width = scrolled + "%";
  }

  useEffect(() => {
    const serviceBtn = document.getElementById("serviceBtn");
    const portfolioBtn = document.getElementById("portfolioBtn");
    const testBtn = document.getElementById("testBtn");
    const abtBtn = document.getElementById("abtBtn");
    const contactBtn = document.getElementById("contactBtn");
    const logo = document.getElementById("logo");

    function changeBackground(event) {
      const active = document.querySelector(".active");
      if (active) {
        active.classList.remove("active");
      }
      event.target.className = "active";
    }

    serviceBtn.addEventListener("click", changeBackground.bind(this));
    portfolioBtn.addEventListener("click", changeBackground.bind(this));
    testBtn.addEventListener("click", changeBackground.bind(this));
    abtBtn.addEventListener("click", changeBackground.bind(this));
    contactBtn.addEventListener("click", changeBackground.bind(this));
    logo.addEventListener("click", changeBackground.bind(this));
  }, []);

  const navigate = useNavigate();

  const gotoHomePage = () => {
    navigate("/");
  };
  const ServicesScreen = () => {
    navigate(`/service`);
  };
  const PortfolioScreen = () => {
    navigate(`/portfolio`);
  };
  const TestimonialScreen = () => {
    navigate(`/testimonial`);
  };
  const gotoAboutUs = () => {
    navigate(`/about-us`);
  };
  const gotoContactUs = () => {
    navigate(`/contact-us`);
  };

  return (
    <header>
      <div className="container header-main">
        <div id="logo" onClick={gotoHomePage}>
          <img src={Logo} alt="" />
        </div>
        <nav className="menu">
          <li id="serviceBtn" onClick={ServicesScreen}>
            Services
          </li>
          <li id="portfolioBtn" onClick={PortfolioScreen}>
            Portfolio
          </li>
          <li id="testBtn" onClick={TestimonialScreen}>
            Testimonial
          </li>
          <li id="abtBtn" onClick={gotoAboutUs}>
            About Us
          </li>
          <li id="contactBtn" onClick={gotoContactUs}>
            Contact Us
          </li>
          {/* <li>Career</li> */}
        </nav>
        <MobileNav />
      </div>
      <div className="scroller-container">
        <div className="scroll-bar" id="progress-bar"></div>
      </div>
    </header>
  );
};

export default Header;
