import * as types from './types';
import { toast } from "react-toastify";
// Init App
export const initApp = () => ({
    type: types.INIT_APP
});


// Send Inquiry
export const SendInquiryRequest = (params) => ({
    type: types.SEND_INQUIRY_REQUEST,
    payload: params
});
export const SendInquirySuccess = () => ({
    type: types.SEND_INQUIRY_SUCCESS,
});
export const SendInquiryFail = (error) => ({
    type: types.SEND_INQUIRY_FAIL,
    payload: { error }
});