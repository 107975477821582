import React from "react";
import "./Loading.scss";

const Loading = () => {
  return (
    <section className="loader-section-main">
      <div className="loader" />
    </section>
  );
};

export default Loading;
