import * as types from '../actions/types';

const initialState = {
    loader: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, loader: false };
        case types.SEND_INQUIRY_REQUEST:
            return { ...state, loader: true };
        case types.SEND_INQUIRY_SUCCESS:
        case types.SEND_INQUIRY_FAIL:
            return { ...state, loader: false };
        default:
            return state;
    }
};

export default reducer;