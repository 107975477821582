import { call, put, takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";
import * as AppService from "../services/AppService";
import * as AppAction from "../actions/AppAction";

export default function* sagas() {
    function* inquiry(action) {
        try {
            const params = action.payload || {};
            const res = yield call(AppService.sendInquiry, params);
            if (res && res.success) {
                yield put(AppAction.SendInquirySuccess(res.message));
            } else {
                yield put(AppAction.SendInquiryFail(res?.message || "Invalid request"));
            }
        } catch (error) {
            console.log(error);
            yield put(
                AppAction.SendInquiryFail(
                    error?.response?.data?.message || "Invalid request"
                )
            );
        }
    }
    yield takeLatest(types.SEND_INQUIRY_REQUEST, inquiry);
}
